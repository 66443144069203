import React from 'react'
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi'
import { useSelector, useDispatch } from 'react-redux'

import { changePage } from '../features/allTours/allToursSlice'

const PageBtnContainer = () => {
    const { numOfPages, page } = useSelector((store) => store.allTours)
    const dispatch = useDispatch()

    const pages = Array.from({ length: numOfPages }, (_, index) => {
        return index + 1
    })

    const nextPage = () => {
       let newPage = page + 1
       if (newPage > numOfPages) {
        newPage = 1
       }
       dispatch(changePage(newPage)) 
    }
    const prevPage = () => {
        let newPage = page - 1
        if (newPage < 1) {
            newPage = numOfPages
        }
        dispatch(changePage(newPage)) 
    }
  return (
    <div className='flex items-center mt-10 gap-2'>
        <button className='flex items-center border-0 bg-[#5a8b26] p-2 text-white uppercase tracking-widest' onClick={prevPage}>
            <HiChevronDoubleLeft />
            prev
        </button>
        <div className='flex'>
            {pages.map((pageNumber) => {
                return (
                    <button
                        type='button'
                        className={pageNumber === page ? 'pageBtn active' : 'pageBtn'}
                        key={pageNumber}
                        onClick={() => dispatch(changePage(pageNumber))}
                    >
                        {pageNumber}
                    </button>
                )
            })}
        </div>
        <button className='flex items-center border-0 bg-[#5a8b26] p-2 text-white uppercase tracking-widest' onClick={nextPage}>
            next
            <HiChevronDoubleRight />
        </button>
    </div>
  )
}

export default PageBtnContainer