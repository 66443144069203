import React from 'react'

import { Navbar, Footer, Banner, Gallery } from '../components'
import img from '../assets/images/tour1.png'
import Image from '../components/Gallery/Image'

const DestinationPage = () => {
  return (
    <div className='font-poppins'>
      <Navbar />
      <Banner title='Destination' img={img} />
      <Image />
      <Footer />
    </div>
  )
}

export default DestinationPage