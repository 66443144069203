import { Home, Register, DestinationDetailsPage, EmailVerified, Login, ResetPassword, ForgotPassword, AboutPage, DestinationPage, ServicePage, ContactPage, ToursPage, TourDetailsPage } from "./pages";
import { Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { AddForm, Success, Cancel } from "./components";


function App() {

  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/tours" element={<ToursPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/destination" element={<DestinationPage />} />
        <Route path="tour/:tourId" element={<TourDetailsPage />} />
        <Route path="/add-form" element={<AddForm /> } />

        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/auth/reset-password' element={<ResetPassword />} />
        <Route path="/destination/:id" element={<DestinationDetailsPage />} />
        <Route path="/user/verify-email" element={<EmailVerified /> } />
        <Route path="/success" element={<Success /> } />
        <Route path="/cancel" element={<Cancel /> } />
      </Routes>
      <ToastContainer position="top-center" />
    </>
  );
}

export default App;
