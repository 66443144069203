import React from 'react'
import { FaRoute, FaTicketAlt, FaHotel } from "react-icons/fa";

const Services = () => {
  return (
    <div className='my-16 bg-[#fbfbfb]'>
        <div className="container mx-auto space-y-16 font-poppins">
            <div className='flex flex-col items-center gap-4'>
                <h4 className='text-[#5a8b26] text-[1.05rem] md:text-[1.2rem] tracking-widest'>SERVICES</h4>
                <h1 className='text-[2rem] md:text-[2.5rem] font-bold'>Tours and Travels</h1>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pb-8 gap-8'>
                <div className='flex flex-col items-center gap-4 px-4 py-12 bg-white hover:shadow-lg transition duration-500'>
                    <div className='border-2 border-[#5a8b26] p-4 text-[#5a8b26] hover:text-white hover:border-transparent hover:bg-[#5a8b26] transition duration-300'>
                        <FaRoute size={40} className=' '  />
                    </div>
                    <h4 className='font-semibold  text-[1.4rem]'>Travel Guide</h4>
                    <p className='text-center text-[16px]'>
                        Our knowledgeable and experienced tour guides will accompany you throughout the journey, providing valuable insights, local anecdotes, and ensuring a seamless travel experience.
                    </p>
                </div>
                <div className='flex flex-col items-center gap-4 px-4 py-12 bg-white hover:shadow-lg transition duration-500'>
                    <div className='border-2 border-[#5a8b26] p-4 text-[#5a8b26] hover:text-white hover:border-transparent hover:bg-[#5a8b26] transition duration-300'>
                        <FaTicketAlt size={40}  />
                    </div>
                    <h4 className='font-semibold  text-[1.4rem]'>Ticket Booking</h4>
                    <p className='text-center text-[16px]'>
                        Our knowledgeable and experienced tour guides will accompany you throughout the journey, providing valuable insights, local anecdotes, and ensuring a seamless travel experience.
                    </p>
                </div>
                <div className='flex flex-col items-center gap-4 px-4 py-12 bg-white hover:shadow-lg transition duration-300'>
                <div className='border-2 border-[#5a8b26] p-4 text-[#5a8b26] hover:text-white hover:border-transparent hover:bg-[#5a8b26] transition duration-300'>
                        <FaHotel size={40}  />
                    </div>
                    <h4 className='font-semibold  text-[1.4rem]'>Hotel Booking</h4>
                    <p className='text-center text-[16px]'>
                        Our knowledgeable and experienced tour guides will accompany you throughout the journey, providing valuable insights, local anecdotes, and ensuring a seamless travel experience.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services