import axios from "axios";
import { getUserFromLocalStorage } from "./localStorage";

const customFetch = axios.create({
    baseURL: 'https://authentication-api-nodejs.onrender.com',
})
// https://authentication-api-nodejs.onrender.com

customFetch.interceptors.request.use((config) => {
    const user = getUserFromLocalStorage()
    if (user) {
      config.headers['Authorization'] = `Bearer ${user}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }) 

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
    if (error.response.status === 401) {
        // thunkAPI.dispatch(clearStore())
        return thunkAPI.rejectWithValue('Unauthorized! Logging Out...')
    }
    return thunkAPI.rejectWithValue(error.response.data.msg)
}
  
export default customFetch