import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import customFetch from "../../utils/axios";
import axios from "axios";

const initialState = {
    tour: null,
    isLoading: false,
    tourData: null
}

export const fetchTour = createAsyncThunk('singleTour/getTours', async(tourId, thunkAPI) => {
    let url = `/tour/${tourId}`

    try {
        const response = await customFetch.get(url)
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.msg)
    }
})

export const postTour = createAsyncThunk(
    'postTour/tours',
    async(tourData, thunkAPI) => {
        try {
            const response = await customFetch.post('/tour', tourData, {
                headers: {
                    Authorization: `Bearer ${thunkAPI.getState().user.user}`
                }
            })

            return response.data
        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.msg)
        }
    }
)

const fetchTourSlice = createSlice({
    name: 'tour',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTour.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchTour.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.tour = payload.tour
            })
            .addCase(fetchTour.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload) 
            })
            .addCase(postTour.pending, (state) => {
                state.isLoading = true
            })
            .addCase(postTour.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.tourData = payload
                toast.success(payload)
            })
            .addCase(postTour.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload)
            })
            

    }
})

export default fetchTourSlice.reducer


