import React from "react";
import Slider from "react-slick";
import { TiMediaPlay, TiMediaPlayReverse } from "react-icons/ti";


import client1 from "../../assets/images/testimony2.webp";
import client2 from "../../assets/images//testimony5.webp";
import client3 from "../../assets/images/testimony6.webp";
import client4 from "../../assets/images/testimonial-1.jpg";


// import './slick.css'
// import './slick.theme.css'
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className='absolute z-10 top-[50%] -translate-y-[50%] -right-10'>
      <button onClick={onClick} className="h-8 w-8 rounded-full bg-[#5a8b26] flex items-center justify-center">
        <TiMediaPlay size={20} color="white" />
      </button>
    </div> 
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className='absolute z-10 top-[50%] -translate-y-[50%] -left-10'>
      <button onClick={onClick} className="h-8 w-8 rounded-full bg-[#5a8b26] flex items-center justify-center">
      <TiMediaPlayReverse size={20} color="white" />
      </button>
    </div> 
  );
}

const Testimonials = () => {
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <div className="ft-slick__dots--custom">
        <div className="loadings" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const items = [
    {
      img: client1,
      review:
        "My experience with this tour company was nothing short of amazing! The guides were knowledgeable and friendly, and every detail of the trip was thoughtfully planned.",
      name: "Kim Danladi",
    },
    {
      img: client2,
      review:
        "I've been on many tours, but this was by far the best. The itinerary was perfect, balancing relaxation and exploration.",
      name: "Comfort Dagot",
    },
    {
      img: client3,
      review:
        " The accommodations were fantastic, and the excursions exceeded my expectations. I’ll definitely be booking another tour soon",
      name: "Cherish Francais",
    },
    {
      img: client4,
      review:
        "Every location we visited was more beautiful than the last, and the local insights made it so much more enriching. Best trip I’ve ever taken!",
      name: "Jerry Bryson",
    },
    
  ];
  return (
    <div className="bg-[#e5e5e5] py-16 px-4  font-poppins">
      <div className="container mx-auto">
        <h4 className="text-center text-[#5a8b26] text-[1.05rem] md:text-[1.2rem] tracking-widest">
          TESTIMONIALS
        </h4>
        <h1 className="text-center text-[1.8rem] mt-3 md:text-[2.5rem] font-bold">What Our Clients Say</h1>
          <Slider
            {...settings}
            className="slider-container mt-10 relative"
          >
            {items.map((item, index) => (
              
                <div
                key={index}
                className="relative w-[320px] min-h-[320px] bg-white p-4 flex flex-col space-y-8 justify-center items-center"
              >
                <div className="absolute top-5 left-[50%] -translate-x-[50%] h-20 w-20 rounded-full flex">
                  <img
                    src={item.img}
                    alt="client"
                    className=" h-20 w-20 object-cover rounded-full place-self-center place-content-center"
                  />
                </div>
                <div className="space-y-4 pt-14">
                  <p className="text-center">{item.review}</p>
                  <p className="text-center text-[1.rem] font-semibold">
                    {item.name}
                  </p>
                </div>
              </div>
              
            ))}
          </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
