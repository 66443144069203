import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import logo from '../../assets/images/Triplogo.png'
import { Button, Input } from '../../components'
import { toast } from 'react-toastify'
import customFetch from '../../utils/axios'


const ForgotPassword = () => {
    const [email , setEmail] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!email) {
            toast.error('Please provide a valid email')
        }
        try {
            const { data } = await customFetch.post('/auth/forgot-password', {
                email
            })
            toast.success(data.msg)
        } catch (error) {
            
        }
    }
  return (
    <div className='bg-[#e5e5e5] h-screen font-poppins'>
        <div className="container h-full w-full flex flex-col gap-8 items-center py-8 ">
            <Link to='/'>
                <img src={logo} alt="logo" className='h-[6rem] w-[14rem] object-contain hover:cursor-pointer' />
            </Link>
            <div className="w-full md:w-[60%] lg:w-[50%] bg-white rounded-md p-8 lg:p-16 flex flex-col gap-4 items-center">
                <h1 className='text-[2rem] text-center font-semibold'>Forgot Password Reset</h1>
                <p className='text-center'>Forgotten your password? Enter your email address below to begin reset process</p>
                <form className='mt-4 w-full space-y-4' onSubmit={handleSubmit}>
                    <Input name='email' value={email} handleChange={(e) => setEmail(e.target.value)} type='email'  placeholder='Email address' />
                    <Button title='submit' />
                </form>
                <p>
                    Don't have an account? <Link to='/register' className='links'>Register</Link>
                </p>
            </div>
        </div>
    </div>
  )
}

export default ForgotPassword