import {
    GET_SINGLE_TOUR_BEGIN,
    GET_SINGLE_TOUR_SUCCESS,
    GET_SINGLE_TOUR_FAILURE,
    GET_TOURS_BEGIN,
    GET_TOURS_SUCCESS,
    GET_TOURS_FAILURE,
} from '../actions'

const tours_reducer = (state, action) => {
    if (action.type === GET_TOURS_BEGIN){
        return {
            ...state,
            tours_loading: true
        }
    }
    if (action.type === GET_TOURS_SUCCESS) {
        // const featured_tours = action.payload.filter((tour) => tour.featured === true)
        return {
            ...state,
            tours_loading: false,
            tours: action.payload,
        }
    }
    if (action.type === GET_TOURS_FAILURE) {
        return {
            ...state, 
            tours_loading: false,
            tours_error: true
        }
    }
    
    throw new Error(`No Matching "${action.type}" - action type`)
}

export default tours_reducer