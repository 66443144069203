import React,{ useState } from "react";
import {TiSocialFacebook, TiSocialInstagram, TiSocialTwitter,TiSocialYoutube, TiSocialLinkedin } from "react-icons/ti";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BiSolidPhoneCall } from "react-icons/bi";
import { MdAlternateEmail } from "react-icons/md";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { postContact, clearFilters } from "../../features/reviewSlice/postContactSlice";

const initialState = {
  name: "",
  email: "",
  subject: "",
};


const Contact = () => {
  const [values, setValues] = useState(initialState)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject } = values;
    // if (!name || !email || subject) {
    //   toast.error("Please Fill Out All Fields");
    //   return;
    // }
    dispatch(postContact({ name, email, subject }))
    setValues({ name: '', email: '', subject: '' })
  };
  return (
    <div className="font-poppins space-y-8 py-8">
      <div className="container mx-auto w-full py-16 flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-1/3 space-y-4">
          <h5 className="text-[#5a8b26] text-[1.2rem]">Talk with our team</h5>
          <h1 className="text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] font-semibold">
            Any Question? <br /> Feel Free to Contact
          </h1>
          <div className="flex items-center gap-4 pt-6">
            <div className="bg-[#faf5ee] rounded-full p-3">
              <TiSocialFacebook size={24} />
            </div>
            <div className="bg-[#faf5ee] rounded-full p-3">
              <TiSocialInstagram size={24} />
            </div>
            <div className="bg-[#faf5ee] rounded-full p-3">
              <TiSocialLinkedin size={24} />
            </div>
            <div className="bg-[#faf5ee] rounded-full p-3">
              <TiSocialTwitter size={24} />
            </div>
            <div className="bg-[#faf5ee] rounded-full p-3">
              <TiSocialYoutube size={24} />
            </div>
          </div>
        </div>
        <form className="w-full md:w-2/3 space-y-4" onSubmit={onSubmit}>
            <div className="flex flex-col md:flex-row gap-4">
                <input
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    className="border-0 bg-[#fbfbfb] p-4 w-full rounded-md outline-none"
                />
                <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    className="border-0 bg-[#fbfbfb] p-4 w-full rounded-md outline-none"
                />
            </div>
            <textarea name="subject" id="subject" value={values.subject} onChange={handleChange} cols="30" rows="10" className='border-0 bg-[#fbfbfb] p-4 w-full rounded-md outline-none text-gray-400'>
                Write Comment
            </textarea>
            <button className='text-white bg-[#5a8b26] font-semibold rounded-md p-4 uppercase'>Submit review</button>
        </form>
      </div>
      <div className="container grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className="flex items-center gap-4 p-8 border-[1px] border-gray-300 rounded-md text-gray-400">
            <div className="bg-[#faf5ee] p-3 rounded-full">
                <FaMapMarkerAlt size={24} color='#5a8b26' />
            </div>
            <p className="text-[0.95rem]">
              Sinoki House: Suite 1, 4th Floor, Plot No 770 Oppo. Ministry of Transport, Centre Business District, Abuja, FCT, Nigeria.
            </p>
        </div>
        <a href="tel:+234 704 467 5637" type="phone" className="flex items-center gap-4 p-8 border-[1px] border-gray-300 rounded-md text-gray-400 hover:no-underline">
            <div className="bg-[#faf5ee] p-3 rounded-full">
                <BiSolidPhoneCall size={24} color='#5a8b26' />
            </div>
            <p className="text-[1.2rem]">+234 708 414 3554</p>
        </a>
        <a href="mailto:tripescape97@gmail.com" className="flex items-center gap-4 p-8 border-[1px] border-gray-300 rounded-md text-gray-400 hover:no-underline">
            <div className="bg-[#faf5ee] p-3 rounded-full">
                <MdAlternateEmail size={24} color='#5a8b26' />
            </div>
            <p className="text-[1.2rem]">tripescape85@gmail.com</p>
        </a>
      </div>
    </div>
  );
};

export default Contact;
