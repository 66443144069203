import React, { useState } from 'react';

function ImageUploader() {
  const [images, setImages] = useState([]);
  const [uploadedUrls, setUploadedUrls] = useState([]);

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages(selectedImages);
  };

  const uploadImages = async () => {
    const uploadedUrls = [];
    const cloud_name = 'jay247'
    const preset_key = 'nu1hqqfk'
    for (const image of images) {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', preset_key);

      try {
        const response = await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
          method: 'POST',
          body: formData,
        });
        console.log(response);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          uploadedUrls.push(data.secure_url);
        } else {
          console.error('Failed to upload image:', image.name);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

    setUploadedUrls(uploadedUrls);
  };
  console.log(uploadedUrls);
  console.log(images);

  const handleSubmit = (e) => {
    e.preventDefault();
    uploadImages();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="file" accept="image/*" multiple onChange={handleImageChange} />
        <button type="submit">Upload</button>
      </form>
      {uploadedUrls.length > 0 && (
        <div>
          <h2>Uploaded URLs:</h2>
          <ul>
            {uploadedUrls.map((url, index) => (
              <li key={index}>{url}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ImageUploader;
