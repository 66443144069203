import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import allToursSlice from "./features/allTours/allToursSlice";
import reviewSlice from "./features/reviewSlice/reviewSlice";
import postContactSlice from "./features/reviewSlice/postContactSlice";
import fetchTourSlice from "./features/tourSlice/tourSlice"
import getTourReviewsSlice from "./features/reviewSlice/reviewTourSlice"
import verifyEmailSlice from './features/user/verifyEmailSlice'

export const store = configureStore({
    reducer: {
        user: userSlice,
        allTours: allToursSlice,
        review: reviewSlice,
        contact: postContactSlice,
        tour: fetchTourSlice,
        tourReviews: getTourReviewsSlice,
        verifyEmail: verifyEmailSlice
    }
})