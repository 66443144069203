import React from 'react'

import { Navbar, Banner, Contact, Footer } from '../components'
import img from '../assets/images/slider3.jpg'

const ContactPage = () => {
  return (
    <div className='font-poppins'>
      <Navbar />
      <Banner title='Contact' img={img}/>
      <Contact />
      <Footer />
    </div>
  )
}

export default ContactPage