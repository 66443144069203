import React from 'react'

const Input = ({ type, placeholder, name, value, handleChange, id }) => {
  return (
    <>
        <input
            id={id}
            name={name}
            type={type}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none"
        />
    </>
  )
}

export default Input