import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import customFetch from "../../utils/axios";

const initialFilterState = {
    priceMin: 0,
    priceMax: 100000,
    search: '',
    category: 'all',
    location: 'all',
    duration: 'all',
    sort: 'high',
    sortOptions: ['high', 'low', 'a-z', 'z-a'],
    categoryOptions: ['city', 'hosted', 'adventure', 'group', 'couple'],
    durationOptions: ['0 - 24', '1 - 2', '2 - 3', '3 - 4', '4 - 5'],
    locationOptions: ['africa', 'london', 'paris', 'america', 'spain']
}

const initialState = {
    isLoading: false,
    tours: [],
    featured_tours: [],
    totalTours: 0,
    numOfPages: 1,
    page: 1,
    ...initialFilterState,
}

export const getAllTours = createAsyncThunk('allTours/getTours', async(_, thunkAPI) => {
    const { search, category, location, duration, sort, page, priceMax, priceMin } = thunkAPI.getState().allTours

    let url = `/tour?category=${category}&location=${location}&duration=${duration}&sort=${sort}&priceMin=${priceMin}&priceMax=${priceMax}&page=${page}`
    if (search) {
        url = url + `&search=${search}`
    }
    try {
        const response = await customFetch.get(url)
        console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue('There was an error')
    }
})

export const getSingleTour = createAsyncThunk('singleTour/getTours', async(_, thunkAPI) => {
    
})

const allToursSlice = createSlice({
    name: 'allTours',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.isLoading = true
        },
        hideLoading: (state) => {
            state.isLoading = false
        },
        handleChange: (state, { payload: { name, value } }) => {
            state.page = 1
            state[name] = value
        },
        clearFilters: (state) => {
            return {
                ...state,
                ...initialFilterState
            }
        },
        changePage: (state, { payload }) => {
            state.page = payload
        } 
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTours.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllTours.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.tours = payload.tours
                state.featured_tours = payload.tours.featured_tour
                state.numOfPages = payload.numOfPages
                state.totalTours = payload.totalTours
            })
            .addCase(getAllTours.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload) 
            })
    }
})

export const { showLoading, hideLoading, handleChange, clearFilters, changePage } = allToursSlice.actions

export default allToursSlice.reducer


