import React from "react";

const SelectInput = ({ name, list, value, defaultValue, handleChange }) => {
  return (
    <div>
      <select
        name={name}
        id={name}
        onChange={handleChange}
        value={value}
        defaultValue={defaultValue}
        className="outline-none rounded-md w-full capitalize"
      >
        {list.map((item, idx) => (
          <option key={idx} value={item} className="">
            {item} tour
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
