import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import customFetch from "../../utils/axios";

const initialState = {
    isLoading: false,
    name: '',
    email: '',
    subject: '',
    msg: ''
}

export const postContact = createAsyncThunk(
    'contact/postContact',
    async (contact, thunkAPI) => {
        try {
            const response = await customFetch.post('/contact', contact)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.msg)
        }
    }
)
const postContactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        clearFilters: (state) => {
            return {
                ...state,
                ...initialState
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postContact.pending, (state) => {
                state.isLoading = true
            })
            .addCase(postContact.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.msg = payload
                toast.success(payload.msg)
            })
            .addCase(postContact.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload) 
            })
    }
})

export const { clearFilters } = postContactSlice.actions

export default postContactSlice.reducer
