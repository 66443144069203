import React from "react";
import "./Hero.css";
import slider1 from "../assets/images/slider1.jpg";
import slider2 from "../assets/images/slider2.jpg";
import slider3 from "../assets/images/slider3.jpg";
import slider4 from '../assets/images/destination-1-2.6b007ca8.png'

import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";

const Hero = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    waitForAnimate: false
  }
  const items = [
    {
      image: slider1,
      heading: "Travel & Adventure",
      text: "This is a paragraph that is big",
    },
    {
      image: slider2,
      heading: "Let's explore earth's attractions",
      text: "This is a paragraph that is big",
    },
    {
      image: slider3,
      heading: "We help people to make memories",
      text: "This is a paragraph that is big",
    },
  ];
  return (
    <div className="relative bg-[#e5e5e5] font-poppins justify-center items-center">
        
      <Slider {...settings} className="relative h-full">
      {items.map((item, index) => (
        <div key={index} className="relative h-full">
          
            <img
              className="d-block w-100 h-[60vh] md:h-[100%] object-cover"
              src={item.image}
              alt="First slide"
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
            ></div>
         
          <div className="absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] place-content-center text-white">
            <h2 className="text-[2rem] md:text-[3rem] lg:text-[4rem] font-bold text-center">{item.heading}</h2>
            {/* <p>{item.text}</p> */}
          </div>
        </div>
      ))}
    </Slider>
    <div className="relative  lg:absolute left-0 lg:left-[50%] lg:translate-x-[-50%] z-10 bg-white rounded-md p-4 gap-4 flex flex-col lg:flex-row justify-center lg:justify-between px-10 items-center mx-auto bottom-[-50px] w-[80%]">
    <div className="flex flex-col w-full">
            <select name="" id="" className="border-2 px-2 py-2 outline-none">
            <option value="">Destination</option>
              <option value="">Africa</option>
              <option value="">America</option>
              <option value="">Europe</option>
              <option value="">South Africa</option>
            </select>
        </div>
        <div className="flex flex-col w-full">
            <select name="" id="" className="border-2 px-2 py-2 outline-none">
            <option value="">Traveler Type</option>
              <option value="">Solo</option>
              <option value="">Family</option>
              <option value="">Couple</option>
              <option value="">Group</option>
            </select>
        </div>
        <div className="flex flex-col w-full">
            <input type="email" placeholder="Email" className="placeholder-black border-2 px-2 py-2 outline-none" />
        </div>
        <div className="flex flex-col w-full">
            <input type="phone" placeholder="Contact no" className="placeholder-black border-2 px-2 py-2 outline-none" />
        </div> 
        <button className="w-full h-full text-white bg-[#5a8b26] px-3 py-2 border-2 border-[#5a8b26]">
          Submit
        </button>
    </div>
    </div>
  );
};

export default Hero;
