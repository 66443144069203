import {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { BsBagCheckFill } from 'react-icons/bs'

import { runFireworks } from '../utils/constants'
import './Success.css'

const Success = () => {
    useEffect(() => {
        runFireworks()
    }, [])
  return (
    <div className="success-wrapper font-poppins">
      <div className="success">
        <p className="icon">
          <BsBagCheckFill />
        </p>
        <h2>We hope to see you soon on our tour!</h2>
        <p className="email-msg">Check your email inbox for the receipt.</p>
        <p className="description">
          If you have any questions, please email
          <a className="email" href="mailto:tripescape97@gamil.com">
            tripescape97@gamil.com
          </a>
        </p>
        <Link to="/tours">
          <button type="button" width="300px" className="p-4 rounded-md text-white bg-[#5a8b26]">
            See more tours
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Success