import React from 'react'
import { Link } from 'react-router-dom'

import partners from '../assets/images/Partners_home.jpg'
import AboutItems from './AboutItems.jsx'
import image1 from '../assets/images/people travel.jpg'
import image2 from '../assets/images/young_couple.jpg'


const About = () => {
  return (
    <div className='bg-[#e5e5e5] py-16 px-8 w-full'>
      <div className="container mx-auto flex flex-col items-center justify-center ">
        <div className='flex flex-col lg:flex-row gap-8 lg:gap-0 w-full my-16 items-center justify-center'>
          <div className='mt w-full lg:w-3/5'>
            <img src={partners} alt="partners" className='h-full w-full object-cover' />
          </div>
          <div className='w-full lg:w-3/5 flex flex-col bg-white text-start items-start py-16 px-4 lg:ml-[-30px] z-10 gap-4'>
            <p className='text-[#5a8b26] tracking-wider'>ABOUT US</p>
            <h1 className='font-bold text-[1.5rem] md:text-[2rem]'>
              We Provide Best Tour Packages In Your Budget
            </h1>
            <p className='text-[1.05rem] md:text-[1.2rem]'>
              Discover the breathtaking beauty of Paradise with our exclusive tour package. Immerse yourself in the captivating landscapes, cultural wonders, and unforgettable experiences that await you on this once-in-a-lifetime journey.
            </p>
            <div className='flex gap-4'>
              <img src={image2} alt="" className='h-40 w-full object-contain' />
              <img src={image1} alt="" className='hidden md:block h-40 w-40 md:w-full object-contain' />
            </div>
            <Link to='/tours' className='bg-[#5a8b26] py-2 px-4'>
              <button className='text-white '>
                Book Now
              </button>
            </Link>
          </div>
        </div>
        <AboutItems />
      </div>
    </div>
  )
}

export default About