import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { MdExpandMore } from "react-icons/md";
import { UseSelector, useDispatch, useSelector } from "react-redux";

import tour_banner from "../../assets/images/destination-1-2.6b007ca8.png";
import SingleTour from "./SingleTour";
import SelectInput from "../SelectInput";
import Button from "../Button";
import InputRange from "../InputRange";
import { clearFilters, getAllTours, handleChange } from "../../features/allTours/allToursSlice";
import PageBtnContainer from "../PageBtnContainer";
import Loader from "../Loader";

const NoTour = ({ all }) => {
  if (all.length === 0) {
    return (
      <h2 className="font-semibold text-[2rem]">No tours to display...</h2>
    )
  }
} 

const TourList = () => {

  const dispatch = useDispatch();
  const {
    tours,
    isLoading,
    search,
    category,
    location,
    duration,
    sort,
    sortOptions,
    categoryOptions,
    locationOptions,
    durationOptions,
    page,
    totalTours,
    numOfPages,
    minPrice,
    maxPrice
  } = useSelector((store) => store.allTours);

  const [selectedPrice, setSelectedPrice] = useState(maxPrice)
  const handleSearch = (e) => {
    if (isLoading) return
    dispatch(handleChange({
      name: e.target.name,
      value: e.target.value
    }))
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(clearFilters())
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target
    setSelectedPrice(value)
    console.log(selectedPrice);
  }

  useEffect(() => {
    dispatch(getAllTours());
  }, [page, search, category, location, duration, sort, minPrice, maxPrice]);

  if (isLoading) {
    return <Loader />
  }
 console.log(tours);
  return (
    <div className="my-16 font-poppins">
      <div className="container mx-auto flex flex-col md:flex-row justify-center items-start gap-8">
        <form className="w-full md:w-1/3 space-y-8">
          <div className="bg-[#fbfbfb] p-6 flex-col space-y-6 rounded-md">
            <h3 className="text-black font-semibold text-[1.5rem]">
              Search Tours
            </h3>
            {/* search name */}
            <input 
              type="search"
              placeholder="Search"
              value={search}
              onChange={handleSearch}
              name="search"
              className="border-0 bg-[#fff] p-4 w-full rounded-md outline-none"
            />
          </div>
          <div className="border-[1px] border-[#e5e5e5] p-10 rounded-md">
            {/* <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h2 className="font-semibold text-[1.rem]">Price</h2>
              </AccordionSummary>
              <AccordionDetails>
                 price 
                <InputRange 
                  name="price" 
                  label="Select Price" 
                  minPrice='0'
                  maxPrice='100000'
                  price={selectedPrice}
                  handleChange={handlePriceChange}
                />
              </AccordionDetails>
            </Accordion> */}
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h2 className="font-semibold text-[1.rem]">Category</h2>
              </AccordionSummary>
              <AccordionDetails>
                <SelectInput name="category" list={['all', ...categoryOptions]} value={category} handleChange={handleSearch} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h2 className="font-semibold text-[1.rem]">Location</h2>
              </AccordionSummary>
              <AccordionDetails>
                {/*  */}
                <SelectInput name="location" list={['all', ...locationOptions]} value={location} handleChange={handleSearch} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h2 className="font-semibold text-[1.rem]">Duration</h2>
              </AccordionSummary>
              <AccordionDetails>
                {/*  */}
                <SelectInput name="duration" list={['all', ...durationOptions]} value={duration} handleChange={handleSearch} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h2 className="font-semibold text-[1.rem]">Sort By</h2>
              </AccordionSummary>
              <AccordionDetails>
                {/*  */}
                <SelectInput name="sort" list={sortOptions} value={sort} handleChange={handleSearch} />
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="flex w-full gap-4">
            <Button title="Clear filters" disabled={isLoading} onClick={handleSubmit} />
          </div>
        </form>
        <div className="w-full md:w-2/3 space-y-8">
          {/* <h4 className="font-semibold capitalize text-[1.5rem]">{totalTours} tour{tours.length > 1 && 's'}</h4> */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {<NoTour all={tours} />}
            {tours.map((tour, idx) => (
              <SingleTour
                key={tour?.id}
                tour={tour}
                description={true}
                img={tour?.images}
              />
            ))}
          </div>
          {numOfPages > 1 && <PageBtnContainer />}
        </div>
      </div>
    </div>
  );
};

export default TourList;
