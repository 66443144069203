import { useState } from "react";
import { FaPhone, FaRegUser } from "react-icons/fa6";
import logo from '../assets/images/Triplogo.png'
import { MdOutlineAlternateEmail } from "react-icons/md"
import {TiSocialFacebook, TiSocialInstagram, TiSocialTwitter,TiSocialYoutube, TiSocialLinkedin } from "react-icons/ti";
import { BsSearch } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import { LiaTimesSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../features/user/userSlice";
import { FaTimes } from "react-icons/fa";

const Navbar = () => {
    const dispatch = useDispatch()
    const { isLaoding, user } = useSelector(store => store.user)
    const [showNav, setShowNav] = useState(false)

    const handleNav = () => {
        setShowNav(!showNav)
    }

    const handleLogout = () => {
        dispatch(logoutUser())
    }

    const socialMediaHover = 'hover:text-[#5a8b26] hover:cursor-pointer transition-all duration-300'

  return (
    <div className='flex-col w-full font-poppins'>
        <div className="hidden md:flex w-full bg-[#313041] px-4 py-3">
            <div className='container mx-auto flex items-center justify-between'>
                <div className='flex text-[#e5e5e5] gap-4 text-sm'>
                    <span className="flex items-center gap-3">
                        <FaPhone color='#5a8b26' />
                        <a href="tel:+2347084143554" className="hover:text-white hover:no-underline transition-all duration-300">+234 708 414 3554</a>
                    </span>
                    <span className="flex items-center gap-3">
                        <MdOutlineAlternateEmail color="#5a8b26" size={20} />
                        <a href="mailto:tripescape85@gmail.com" className="hover:text-white hover:no-underline transition-all duration-300">tripescape85@gmail.com</a>
                    </span>
                </div>
                <div className="flex text-white gap-4">
                    <TiSocialFacebook size={24} className={socialMediaHover} />
                    <TiSocialInstagram size={24} className={socialMediaHover} />
                    <TiSocialLinkedin size={24} className={socialMediaHover} />
                    <TiSocialTwitter size={24} className={socialMediaHover} />
                    <TiSocialYoutube size={24} className={socialMediaHover} />
                </div>
            </div>
            
        </div>
        <div className="w-full bg-white">
        <nav className="container mx-auto flex w-full justify-between items-center bg-white">
            <Link to='/'><img src={logo} alt="logo" className="h-[6rem] w-[14rem] object-contain hover:cursor-pointer" /></Link>
            <div className="hidden lg:flex gap-6 font-semibold">
                <NavLink className={({ isActive }) => isActive ? 'nav-link-active nav-link' : 'nav-link'} to='/'>Home </NavLink>
                <Link className="nav-link" to='/about'>About</Link>
                <Link className="nav-link" to='/destination'>Destination</Link>
                <Link className="nav-link" to='/tours'>Tours</Link>
                <Link className="nav-link" to='/services'>Services</Link>
                <Link className="nav-link" to='/contact'>Contact</Link>
            </div>
            {showNav ? (
                <div onClick={handleNav} className="cursor-pointer">
                <LiaTimesSolid size={24} className="block lg:hidden" />
            </div>
            ) : (
                <div onClick={handleNav} className="cursor-pointer">
                <CiMenuBurger size={24} className="block lg:hidden" />
            </div>
            )}
            <div className="hidden sm:flex gap-4 items-center">
                {/* <Link to='' className="bg-gray-200 rounded-full p-4 hover:bg-[#5a8b26] hover:text-white transition-all duration-300">
                    <BsSearch />
                </Link> */}
                {!user ? (
                    <Link to='/login' className="bg-gray-200 rounded-full p-4 hover:bg-[#5a8b26] hover:text-white transition-all duration-300">
                        <FaRegUser />
                    </Link>
                ) : (
                    <button
                        className="text-white px-4 py-3 rounded-md bg-[#5a8b26]"
                        onClick={handleLogout}
                    >
                        Logout
                    </button>
                )}
            </div>
            
        </nav>
        </div>
        {showNav && (
            <nav
            className={`absolute top-0 left-0 w-full p-4 items-start z-50 bg-white shadow-lg transition-all duration-900 ease-in transform ${
              showNav ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'
            }`}
          >
            <div className="flex justify-end">
                <FaTimes size={24} color='#000000' onClick={() => setShowNav(false)} />
            </div>
            <div className="container mx-auto flex flex-col gap-8 font-semibold">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/destination">Destinations</Link>
              <Link to="/tours">Tours</Link>
              <Link to="/services">Services</Link>
              <Link to="/contact">Contact</Link>
              <Link to="/register">Register</Link>
            </div>
          </nav>
        )}
    </div>
  )
}

export default Navbar