import React from 'react'
import { Navbar, Banner, Footer, Destination } from '../components'
import destination from '../assets/images/people travel.jpg'

const DestinationDetailsPage = () => {
  return (
    <div className='font-poppins'>
        <Navbar />
        <Banner title='Destination Details' img={destination} />
        <Destination />
        <Footer />
    </div>
  )
}

export default DestinationDetailsPage