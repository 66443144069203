import React, { useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import customFetch from '../../utils/axios';
import Loader from '../../components/Loader'
import { verifyEmailFailure, verifyEmailRequest, verifyEmailSuccess } from '../../features/user/verifyEmailSlice';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const EmailVerified = () => {
    const query = useQuery()
    const dispatch = useDispatch()
    const { loading, error, success } = useSelector((state) => state.verifyEmail)
    const verifyToken = async () => {
        dispatch(verifyEmailRequest())
        try {
            const { data } = await customFetch.post('/auth/verify-email', {
                verificationToken: query.get('token'),
                email: query.get('email')
            })
           
            dispatch(verifyEmailSuccess())
        } catch (error) {
            dispatch(verifyEmailFailure(error.message))
        }
    } 
    useEffect(() => {
        verifyToken()
    }, [])
    if (loading) {
        return <Loader />
    }
    if (error) {
        return <h1>Error</h1>
    }
    if (success) {
        return (
            <div className='flex flex-col items-center justify-center h-screen w-screen gap-4'>
                <h2 className='text-[2rem] font-bold text-[#5a8b26]'>Account Confirmed</h2>
                <Link to='/login' className='p-4 text-[1.2rem] bg-[#5a8b26] text-white rounded-md'>
                    Please Login
                </Link>
            </div>
        ) 
    }
    return null
}

export default EmailVerified