import React from 'react'
import { FaSquarePhone, FaLocationDot } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import { GrStatusGood } from "react-icons/gr";
import { FaLongArrowAltUp } from "react-icons/fa";
import {TiSocialFacebook, TiSocialInstagram, TiSocialTwitter,TiSocialYoutube, TiSocialLinkedin } from "react-icons/ti";


import logo from '../assets/images/Triplogo.png'

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  const footer__links = [
    {
      name: 'flight ticket',
      href: '/',
    },
    {
      name: 'Hotel Booking',
      href: '/',
    },
    {
      name: 'Visa Attendance',
      href: '/',
    },
    {
      name: 'Airport Transfer',
      href: '/',
    },
    {
      name: 'Travel Insurance',
      href: '/',
    },
    {
      name: 'Cruise Booking',
      href: '/',
    },
    {
      name: 'Car Rentals',
      href: '/',
    },
  ]
  const nav__links = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'About',
      href: '/',
    },
    {
      name: 'Services',
      href: '/',
    },
    {
      name: 'Tour',
      href: '/',
    },
    {
      name: 'Contact',
      href: '/',
    },
  ]
  const socialMediaStyling = 'bg-[#faf5ee] rounded-full p-3 hover:cursor-pointer hover:bg-[#5a8b26] hover:text-white duration-300'
  return (
    <div className='bg-[#313040] text-[#a09fad] text-sm'>
      <div className="container mx-auto py-16">
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-6'>
          <div className='flex flex-col w-full gap-4'>
            <div>
              <img src={logo} alt="logo" className="h-[6rem] w-[14rem] object-contain" />
              <p className='leading-8'>
                Welcome to our TripEscape and Tour Agency.
              </p>
            </div>
            <hr className='w-full' style={{ backgroundColor: '#464554', width: '60%' }} />
            <div className='space-y-4'>
              <div className='flex items-center gap-4'>
                <FaSquarePhone size={20} color='#5a8b26' />
                <a href='tel:+234 703 894 9450' className='hover:no-underline hover:text-[#5a8b26] duration-300'>+234 708 414 3554</a>
              </div>
              <div className='flex items-center gap-4'>
                <FaEnvelope size={20} color='#5a8b26' />
                <a href='mailto:tripescape85@gmail.com' className='hover:no-underline hover:text-[#5a8b26] duration-300'>tripescape85@gmail.com</a>
              </div>
              <div className='flex items-center gap-4'>
                <FaLocationDot size={20} color='#5a8b26' />
                <p>
                Sinoki House: Suite 1, 4th Floor, Plot No 770 Oppo. Ministry of Transport, Centre Business District, Abuja, FCT, Nigeria.
                </p>
              </div>
            </div>
          </div>
          <div className='w-full flex gap-6'>
            <div className=''>
              <h4 className='tracking-widest text-white text-[1.2rem] uppercase font-semibold'>Our Services</h4>
              {footer__links.map((item, idx) => (
                <div key={idx} className='flex items-center gap-2 font-semibold mt-4 hover:text-[#5a8b26] transition duration-300'>
                  <IoIosArrowForward />
                  <Link to={item.href} className='hover:no-underline hover:text-[#5a8b26] capitalize'>{item.name}</Link>
                </div>
              ))}
            </div>
            <div className=''>
              <h4 className='tracking-widest text-white text-[1.2rem] uppercase font-semibold'>useful links</h4>
              <div className=''>
                {nav__links.map((item, idx) => (
                  <div key={idx} className='flex items-center gap-2 font-semibold mt-4 hover:text-[#5a8b26] transition duration-300'>
                    <IoIosArrowForward />
                    <Link to={item.href} className='hover:no-underline hover:text-[#5a8b26] capitalize'>{item.name}</Link>
                  </div>
              ))}
              </div>
            </div>
          </div>
          <div className='space-y-8 w-full'>
            <h4 className='text-white font-semibold text-[1.2rem]'>Newsletter</h4>
            <form className='space-y-3'>
              <input type="email" placeholder='Email address' className='border-0 bg-[#292837] p-4 w-full rounded-md outline-none' />
              <button className='border-0 bg-[#5a8b26] p-4 w-full rounded-md text-white uppercase tracking-widest font-semibold text-[15px]'>subcribe</button>
              <div className='flex items-center gap-2'>
                <GrStatusGood size={20} /> 
                <span className='font-semibold'>I agree to all terms and policies</span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='flex bg-white ml-4 md:ml-28 rounded-tl-md'>
        <div className='bg-[#5a8b26] p-7 md:p-10 rounded-tl-md w-36 flex justify-center cursor-pointer' onClick={scrollToTop}>
          <FaLongArrowAltUp size={24} className='text-white' />
        </div>
        <div className='w-full flex items-center justify-between'>
          <div className="hidden md:flex items-center ml-0 md:ml-8 text-[#313040] gap-4">
            <div className={socialMediaStyling}>
              <TiSocialFacebook size={24} />
            </div>
            <div className={socialMediaStyling}>
              <TiSocialInstagram size={24} />
            </div>
            <div className={socialMediaStyling}>
              <TiSocialLinkedin size={24} />
            </div>
            <div className={socialMediaStyling}>
              <TiSocialTwitter size={24} />
            </div>
            <div className={socialMediaStyling}>
              <TiSocialYoutube size={24} />
            </div>
          </div>
          <p className='place-self-center text-center text-[#313040] md:font-semibold mr-28'>
            Copyright © Tripescape. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer