import React, { useEffect, useState } from "react";
import { FaRegClock } from "react-icons/fa6";
import { FaRegUserCircle, FaShare, FaTimes } from "react-icons/fa";
import { SlPlane } from "react-icons/sl";
import { FiMapPin } from "react-icons/fi";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { MdExpandMore } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { Rating } from "@mui/material";
import moment from "moment";

import Review from "../Review/Review";

import { useSelector, useDispatch } from "react-redux";
import { fetchTour } from "../../features/tourSlice/tourSlice";
import { useParams } from "react-router-dom";
import { formatPrice } from "../../utils/constants";
import CustomPaging from "../CustomPaging";
import { getTourReviews } from "../../features/reviewSlice/reviewTourSlice";
import BookTour from "./BookTour";
import Loader from "../Loader";

const TourDetails = () => {
  const [value, setValue] = useState(5);
  const dispatch = useDispatch();
  const { tour, isLoading } = useSelector((store) => store.tour);
  const { reviews } = useSelector((store) => store.tourReviews);
  const { tourId } = useParams();

  const formatedDate = moment(tour?.createdAt).startOf("day").fromNow();
  const nairaAmount = formatPrice(tour?.amount);
  const tourItems = [
    {
      icon: FaRegClock,
      p1: "Duration",
      p2: `${tour?.duration} Days`,
    },
    {
      icon: FaRegUserCircle,
      p1: "Min Age",
      p2: `${tour?.minAge} +`,
    },
    {
      icon: SlPlane,
      p1: "Tour Type",
      p2: tour?.category,
    },
    {
      icon: FiMapPin,
      p1: "Location",
      p2: tour?.location,
    },
  ];

  useEffect(() => {
    dispatch(fetchTour(tourId));
    dispatch(getTourReviews(tourId));
  }, [tourId]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) return <Loader /> 
  return (
    <div className="font-poppins bg-white overflow-hidden">
      <div className="bg-[#fbfbfb] text-[#80779c]">
        <div>
          <CustomPaging images={tour?.images} />
        </div>
        {/* <div className="container flex flex-col md:flex-row gap-16 justify-center items-center mt-8 py-12">
          <div className="w-full md:w-2/5">
            <div>
              <h1 className="font-semibold text-[1.5rem] text-black">
                {tour?.name}
              </h1>
              <p>
                <span className="text-[#5a8b26] font-semibold text-[1.3rem]">
                  {nairaAmount}
                </span>
                <span> / Per Person</span>
              </p>
            </div>
          </div>
          <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:w-3/5">
            {tourItems.map((item, idx) => (
              <div key={idx} className="flex items-center justify-center gap-3">
                <item.icon size={40} color="#5a8b26" />
                <div className="capitalize">
                  <p>{item.p1}</p>
                  <p className="text-black">{item.p2}</p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
      {/* <div className="py-4">
        <div className="container">
          <div className="flex justify-between items-center">
            <div className="flex items-center divide-x-[1px] divide-gray-400 gap-4">
              <span className="flex items-center">
                <FaRegClock />{" "}
                <span className="ml-2">Posted {formatedDate}</span>
              </span>
              <span className="pl-4 flex items-center gap-2">
                <Rating name="read-only" value={tour?.averageRating} readOnly />
                <span className="hidden md:block">8.0 Superb</span>
              </span>
            </div>
            <button className="flex items-center bg-[#fbfbfb] px-3 py-2 rounded-md">
              <FaShare className="mr-2" /> SHARE
            </button>
          </div>
        </div>
      </div> */}
      <div className="py-8">
        <div className="container flex flex-col md:flex-row gap-8 ">
          <div className="w-full lg:w-3/5 flex flex-col gap-8">
            <h1 className="font-semibold text-[2rem]">Overview</h1>
            <p className="leading-7">{tour?.overview}</p>

            {/* <div className="space-y-4">
              <h2 className="text-[1.6rem] font-semibold">Incuded</h2>
              {tour?.included?.map((i, index) => (
                <span key={index} className="flex items-center gap-4">
                  <TiTick size={24} color="5a8b26" />
                  <span>{i}</span>
                </span>
              ))}
            </div> */}

            {/* <div className="space-y-4">
              <h2 className="text-[1.6rem] font-semibold">Excluded</h2>
              {tour?.excluded?.map((i, index) => (
                <span key={index} className="flex items-center gap-4">
                  <FaTimes size={24} color="5a8b26" />
                  <span>{i}</span>
                </span>
              ))}
            </div> */}

            {/* <div className="space-y-4">
              <h1 className="font-semibold text-[1.7rem] mb-4">Tour Plans</h1>
              <div>
                {tour?.tourPlans.map((plan, idx) => (
                  <Accordion key={idx}>
                    <AccordionSummary
                      expandIcon={<MdExpandMore />}
                      aria-controls={`panel${idx + 1}-content`}
                      id={`panel${idx + 1}-header`}
                    >
                      <h2 className="font-[400] text-[1.rem] uppercase">
                        <span className="text-[#5a8b26] font-bold">Day {idx + 1}</span>{" "}
                        {plan[0]}
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{plan[1]}</p>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>

              
              <Review reviews={reviews} />
            </div> */}
          </div>
          {/* <div className="w-full lg:w-2/5">
            <BookTour tour={tour} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TourDetails;
