import React from 'react'
import { Navbar, Footer, Banner } from '../components'
import AboutUs from '../components/About/AboutUs'
import img from '../assets/images/slider2.jpg'

const AboutPage = () => {
  return (
    <div className='bg-white font-poppins text-[#65657e]'>
        <Navbar />
        <Banner title='About' img={img} />
        <AboutUs />
        <Footer />
    </div>
  )
}

export default AboutPage