import React from 'react'
import { Navbar, Footer, TourDetails } from '../components'

const TourDetailsPage = () => {
  return (
    <div className='font-poppins'>
        <Navbar />
        <TourDetails />
        <Footer />
    </div>
  )
}

export default TourDetailsPage