import React from 'react'
import { BiSolidBullseye } from "react-icons/bi";
import { FaGlobe } from "react-icons/fa";

import about from '../../assets/images/about.avif'
import about1 from '../../assets/images/team.jpg'
import about2 from '../../assets/images/MD_Tripescape.jpg'

const AboutUs = () => {
    const items = [
        {
            p1: 'BEST VALUE HOLIDAY PACKAGES',
            p2: 'We offer you the opportunity to experience, explore, embrace and enjoy new countries, cities and cultures across the globe, without leaving your own culture at home.',
        },
        {
            p1: 'MANAGED TOURS, COMPLETE PEACE OF MIND',
            p2: 'Each of our tours is led by a highly trained and experienced Tour Manager, who has the knowledge and skill to guide and help you make the most of every new exciting destination.',
        },
        {
            p1: 'YOUR HOLIDAY, OUR PROMISE',
            p2: 'Our promise to you is simple: we’ll do our utmost to ensure you With Tripescape you will undoubtedly create a memorable experience which you will cherish forever.',
        },
        {
            p1: 'WE TAKE YOUR FUN SERIOUSLY',
            p2: 'Travel should be fun and our tours are designed to turn your dream holiday into reality. Our top priority is you. Everything we do is to enhance your experiences.',
        },
    ]
  return (
    <div className='bg-white'>
        <div className='container space-y-16 mb-16'>
            <div className='flex flex-col lg:flex-row gap-8 lg:gap-0 w-full my-16 items-center justify-center'>
                <div className='mt w-full lg:w-3/5'>
                    <img src={about1} alt="partners" className='h-full w-full object-cover' />
                </div>
                <div className='w-full lg:w-3/5 flex flex-col bg-[#fbfbfb] text-start py-16 px-4 lg:ml-[-30px] z-10 gap-4'>
                    <p className='text-[#5a8b26] tracking-wider'>ABOUT US</p>
                    <h1 className='font-bold text-[1.5rem] md:text-[2.5rem] text-black'>
                        Our Company
                    </h1>
                    <p className='text-[1.05rem] md:text-[17px]'>
                        To travel is to live and Tripescape Travel strive to make it easy to do the same by partnering with a wide variety of airlines and travel providers to fulfill all your travel needs. Our dedicated round-the-clock customer support has the solution of your every query. Since the inception our business module has evolved over to include; Air Consolidation, Corporate Travel Management, Retail Travel Services, Packaged Holidays, Religious Tours and Cruise Tours, Special Interest Groups, Training in Travel & Tourism, Student Travel, Travel Technology etc.
                    </p>
                    <p className='text-[1.05rem] md:text-[17px]'>
                        Our Aim is to build relationship with our clients on trust, confidence and honesty, providing them the high-quality services and the best innovative technology. We always work to control the cost of travel, keep the traveler safe to make the travel easy and comfortable for our travelers.
                    </p>
                </div>
            </div>

            {/* Mission and Vision */}
            <div className='flex flex-col md:flex-row gap-8'>
                <div className='bg-[#fbfbfb] flex flex-col gap-4 p-8 text-[17px]'>
                    <p className='text-[#5a8b26] tracking-wider uppercase'>Know about us</p>
                    <h1 className='text-[2.5rem] font-bold flex items-center gap-4 text-black'>
                        <span>Our Mission</span><BiSolidBullseye />
                    </h1>
                    <p>
                    Our mission at Tripescape.ng is to create exceptional travel experiences that inspire and enrich the lives of our clients.
                    </p>
                    <p>
                        We strive to deliver unparalleled service, attention to detail, and personalized itineraries that cater to the unique preferences and desires of each traveler and inspire a love for exploration that extends far beyond the boundaries of your trips.
                    </p>
                    <p>
                        With a focus on quality, sustainability, and cultural appreciation, we aim to exceed expectations, foster lifelong memories, and promote a deep appreciation for the diverse world we explore.
                    </p>
                </div>
                <div className='bg-[#fbfbfb] flex flex-col gap-4 p-8 text-[17px]'>
                    <p className='text-[#5a8b26] tracking-wider uppercase'>Know about us</p>
                    <h1 className='text-[2.5rem] font-bold flex items-center gap-4 text-black'>
                        <span>Our Vision</span><FaGlobe />
                    </h1>
                    <p>
                    Our vision is to be a leading global tour and travel company known for providing transformative journeys that connect people with captivating destinations.
                    </p>
                    <p>
                    We envision ourselves as a trusted partner, offering innovative and immersive experiences that showcase the beauty, culture, and natural wonders of each destination.
                    </p>
                    <p>
                    By embracing sustainable travel practices, promoting local communities, and fostering meaningful connections between travelers and their surroundings, we aim to be a catalyst for positive change in the travel industry.
                    </p>
                </div>
            </div>

            {/* why choose tripescape */}
            <div className='flex flex-col-reverse md:flex-row gap-8 items-center justify-center'>
                <div className='flex flex-col gap-4 bg-[#fbfbfb] p-8 w-full md:w-1/2'>
                    <p className='text-[#5a8b26] tracking-wider uppercase'>about us</p>
                    <h1 className='text-[2.5rem] font-bold flex items-center gap-4 text-black'>Why Choose Tripescape</h1>
                    {items.map((item, idx) => (
                        <div key={idx} className='space-y-2'>
                            <p className='uppercase text-black font-semibold'>{item.p1}</p>
                            <p>{item.p2}</p>
                        </div>
                    ))}
                </div>
                <img className='w-full md:w-1/2' src={about} alt="about us" />
            </div>

            {/* management desk */}
            <div className='space-y-8'>
                <h1 className='font-bold text-black text-center text-[1.5rem] md:text-[2rem] lg:text-[2.5rem]'>Management Desk</h1>
                <div className='flex flex-col md:flex-row gap-8'>
                    <div className='w-full md:w-1/4'>
                        <img src={about2} alt="md" />
                        <div className='p-4 text-center bg-[#fbfbfb]'>
                            <p className='text-black font-semibold'>Ernest Chiemela Uche</p>
                            <p>Managing Director</p>
                        </div>
                    </div>
                    <div className='w-full md:w-3/4 bg-[#fbfbfb] flex flex-col justify-center p-4 space-y-4'>
                        <p>
                            Progress for me has never been a dream. It has always been a distant reality and once one milestone is achieved, there is always another one waiting to be crossed. It is with this belief that I started Tripescape Travel, a company that was in the business of Travel & Tourism
                        </p>
                        <p>
                            At Tripescape Travel, the appeasement of clients is the trait by which the company measures its quality of service. The dedicated staff is always available to take care of client's holiday planning, visa consultation, air ticketing, hotel booking, and transportation services, making sure that clients can focus on their business or pleasure without any worries.
                        </p>
                        <p>
                            The company ensures that clients are provided with the best possible tailored solutions to suit their needs. The journey of Tripescape is never ending. But with the support of my team and clients, I am confident that the company will continue to scale milestones of excellence for years to come.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs