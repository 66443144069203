import React from 'react'
import { Link } from 'react-router-dom'

const Cancel = () => {
  return (
    <div className='flex items-center h-screen w-screen justify-center bg-white p-16 font-poppins'>
        <div className='flex flex-col items-center bg-[#e5e5e5] p-8 space-y-8 rounded-md'>
            <p className='text-2xl font-bold text-red-700'>OOPs!!! Something went wrong</p>
            <Link to='/' className='w-[80%] p-4 rounded-md text-white bg-[#5a8b26] text-center text-xl hover:no-underline'>
                Go to Home
            </Link>
        </div>
    </div>
  )
}

export default Cancel