import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import customFetch from "../../utils/axios";
import axios from "axios";
import { getUserFromLocalStorage, addUserToLocalStorage, removeUserFromLocalStorage } from "../../utils/localStorage";


const initialState = {
    isLoading: false,
    user: getUserFromLocalStorage(),
}
 
const url = 'http://localhost:5000'

export const registerUser = createAsyncThunk(
    'user/registerUser',
    async (user, thunkAPI) => {
        try {
            const response = await axios.post(`${url}/auth/register`, user)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.msg)
        }
    }
)

export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (user, thunkAPI) => {
        try {
            const response = await axios.post(`${url}/auth/login`, user)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.msg)
        }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logoutUser: (state, { payload }) => {
            state.user = null;
            removeUserFromLocalStorage();
            toast.success('Successfully logged out');
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
            state.isLoading = true
            })
            .addCase(registerUser.fulfilled, (state, { payload }) => {
            const { user } = payload
            console.log(payload);
            state.isLoading = false
            state.user = user
            toast.success(`Successfully registered`)
            })
            .addCase(registerUser.rejected, (state, { payload }) => {
            state.isLoading = false
            toast.error(payload)
            })
            .addCase(loginUser.pending, (state) => {
            state.isLoading = true
            })
            .addCase(loginUser.fulfilled, (state, { payload }) => {
            const { user } = payload
            state.isLoading = false
            state.user = user
            addUserToLocalStorage(user.token);
            toast.success(`Welcome back ${user.firstName}`)
            })
            .addCase(loginUser.rejected, (state, { payload }) => {
            state.isLoading = false
            toast.error(payload)
            })
    } 
})

export const { logoutUser } = userSlice.actions

export default userSlice.reducer
