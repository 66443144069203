import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import { formatPrice } from "../../utils/constants";
import { FaCalendarAlt, FaUserAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const SingleTour = ({ idx, tour, img, to }) => {

  return (
    <Link to={`/tour/${tour?.id}`}
      key={idx}
      className="flex flex-col items-start justify-center shadow-sm hover:no-underline hover:text-black rounded-md hover:scale-105 duration-500 transition-all"
    >
      <div className="h-60 w-full">
        <img
          src={tour?.images[0]}
          alt="Tour Image"
          className=" object-cover w-full h-full"
        />
      </div>
      <div className=" px-4 pt-4 space-y-3 bg-white w-full h-full">
        <span className="flex items-center gap-2">
          <Rating name="read-only" value={tour?.averageRating} readOnly />
          <span>8.0 Superb</span>
        </span>
        <h2 className="text-[1.4rem] font-semibold">{tour?.name}</h2>
        <p>
          <span className="font-semibold text-[1.5rem] text-[#5a8b26]">
            {/* {formatPrice(tour?.amount)} */} {" "}
          </span>{" "}
          {/* Per Person */}
        </p>
        <hr className="w-full"/>
        {/* {description && <p>{item.description}</p>} */}
        <span className="mb-4 flex justify-between pt-2 text-xs">
          <span className="flex items-center gap-2 text-[#5a8b26]">
            <FaCalendarAlt />
            <p>{tour?.duration} Days</p>
          </span>
          <span className="flex items-center gap-2 text-[#5a8b26]">
            <FaUserAlt />
            <p>{tour?.noOfPersons}</p>
          </span>
          <span className="flex items-center gap-2 text-[#5a8b26]">
            <FaLocationDot />
            <p>{tour?.location}</p>
          </span>         
        </span>
      </div>
    </Link>
  );
};

export default SingleTour;
