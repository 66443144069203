import React, { useState } from 'react'
import Slider from "react-slick";
import { FaTimes } from 'react-icons/fa';
import { GrPrevious, GrNext } from 'react-icons/gr';

import slide1 from '../../assets/images/slider1.jpg'
import slide2 from '../../assets/images/slider2.jpg'
import slide3 from '../../assets/images/slider3.jpg'
import slide4 from '../../assets/images/slider1.jpg'
import slide5 from '../../assets/images/destination-1-2.6b007ca8.png'
import slide6 from '../../assets/images/honeymoon_bg.jpg'

import './slick.theme.css'
import './slick.css'


function Slide() {
  const [data, setData] = useState({ img: '', i: 0 })
  const images = [
    slide1, slide2, slide3, slide4, slide5, slide6
  ]

    const viewImage = (img, i) => {
        setData({img, i})
    }
    console.log(data);

    const imgAction = (action) => {
      let i = data.i;
      const totalImages = images.length;
  
      if (action === 'next-img') {
          i = (i + 1) % totalImages;
          setData({ img: images[i], i: i })
      } 
      
      if (action === 'prev-img') {
          i = (i - 1 + totalImages) % totalImages;
          setData({ img: images[i], i: i })
      } 
      
      if(!action) {
          setData({ img: '', i: 0 })
     }
      
  };
  const settings = {
    className: "",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  return (
    <div className="relative">
      {data.img && 
        <div style={{ 
            width: '100%',
            height: '100vh',
            background: 'black',
            position: 'fixed',
            top: '0',
            left: '0',
            bottom: '0',
            right: '0',
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
            overflow: 'hidden',
            zIndex: '100',  
            margin: 'auto',
            gap: '24px'
        }}>
            <FaTimes onClick={() => imgAction()} className='absolute top-5 right-10 cursor-pointer' color='white' size={24}  />
            <GrPrevious size={24} color='white' onClick={() => imgAction('prev-img')} />
            <img src={data.img} style={{width: 'auto', maxHeight: '90%', maxWidth: '90%', top: '0'}} alt="" />
            <GrNext size={24} color='white' onClick={() => imgAction('next-img')} />
        </div>    
    }
      <Slider {...settings} className='relative h-full'>
        {images.map((img, i) => (

            <div key={i} className=''>
                <img onClick={() => viewImage(img, i)}
                  className='myslide d-block w-100 h-[100%] object-cover hover:cursor-pointer' src={img} alt="" />
                
            </div>
            
        ))}
      </Slider>
    </div>
  );
}

export default Slide;
