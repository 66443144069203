import React from "react";

const Button = ({ title, disabled, onClick }) => {
  return (
    <div>
      <button onClick={onClick} disabled={disabled} className="border-0 bg-[#5a8b26] p-4 w-full rounded-md text-white uppercase tracking-widest font-semibold text-[15px]">
        {title}
      </button>
    </div>
  );
};

export default Button;
