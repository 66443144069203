import React from 'react'

import { Navbar, Banner, Footer } from '../components'
import TourList from '../components/Tour/TourList'
import Loading from '../components/Loading'

import img from '../assets/images/slider1.jpg'

const ToursPage = () => {
  return (
    <div className='font-poppins'>
      <Navbar />
      <Banner img={img} title='Our Tour Packages' urls={'/tours'} />
      <TourList />
      <Footer />
    </div>
  )
}

export default ToursPage