import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { GrNext, GrPrevious } from "react-icons/gr";
import Slider from "react-slick";


function CustomPaging({images}) {

    const [data, setData] = useState({ image: "", i: 0 });

    const viewImage = (image, i) => {
      setData({ image, i });
    };
  
    const imgAction = (action) => {
      let i = data.i;
      const totalImages = images.length;
  
      if (action === "next-img") {
        i = (i + 1) % totalImages;
        setData({ image: images[i], i: i });
      }
  
      if (action === "prev-img") {
        i = (i - 1 + totalImages) % totalImages;
        setData({ image: images[i], i: i });
      }
  
      if (!action) {
        setData({ image: "", i: 0 });
      }
    };
  const settings = {
    customPaging: function(index) {
      return (
        <a className="h-40 w-40">
          <img className="h-full w-full" src={images[index]} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear"
  };

  return (
    <>
    {data.image && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            background: "black",
            position: "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            zIndex: "100",
            margin: "auto",
            gap: "20px",
          }}
        >
          <FaTimes
            onClick={() => imgAction()}
            className="absolute top-10 right-10 cursor-pointer"
            color="white"
            size={24}
          />
          <GrPrevious
            className="cursor-pointer font-bold"
            size={24}
            color="white"
            onClick={() => imgAction("prev-img")}
          />
          <img
            src={data.image}
            style={{
              width: "auto",
              maxHeight: "90%",
              maxWidth: "90%",
              top: "0",
            }}
            alt=""
          />
          <GrNext
            className="cursor-pointer font-bold"
            size={24}
            color="white"
            onClick={() => imgAction("next-img")}
          />
        </div>
      )}
    <div className="slider-container w-full">
      <Slider {...settings} className="flex">
        {images?.map((image, index) => (
            <div key={index}>
                <div className="h-[80vh] flex" style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
                    <img 
                        onClick={() => viewImage(image, index)} 
                        src={image} 
                        className="cursor-pointer w-[100%] h-[100%] object-cover bg-repeat-x"
                        style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}   
                      />
                        
                </div>
            </div>
        ))}
      </Slider>
    </div>
    </>
  );
}

export default CustomPaging;
 