import React from 'react'
import { Link } from 'react-router-dom'

import image1 from '../../assets/images/destination-1-4.0ba8a85a.png'
import tour1 from '../../assets/images/tour1.png'
import tour2 from '../../assets/images/tour2.png'
import tour3 from '../../assets/images/tour3.jpg'
import tour4 from '../../assets/images/tour4.png'
import tour5 from '../../assets/images/tour5.png'
import tour6 from '../../assets/images/tour6.png'
import './Image.css'

const Image = () => {
    const images = [
        {
          src: tour1,
          caption: 'Africa'
        },
        {
          src: tour2,
          caption: 'London'
        },
        {
          src: tour3,
          caption: 'Spain'
        },
        {
          src: tour4,
          caption: 'Paris'
        },
        {
          src: tour5,
          caption: 'Germany'
        },
        {
          src: tour6,
          caption: 'Mexico'
        },
      ]
  return (
    <div className='bg-[#fbfbfb] main-area'> 
      <div className=' container mx-auto my-16 flex flex-col gap-2 items-center justify-center'>
        <h4 className='text-[#5a8b26] text-[1.2rem] tracking-widest text-uppercase'>Destination List</h4>
        <h1 className='text-[2.5rem] font-bold text-center'>Go To Exotic Places</h1>
        <div className="my-8 image-area grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {images.map((item, idx) => (
                <Link to={`/destination/${idx}`} key={idx} className="single-image hover:no-underline cursor-pointer">
                    <img src={item.src} alt="" />
                    <div className="image-text">
                        <h2>{item.caption}</h2>
                        <p>Lorem ipsum dolor sit amet.</p>
                        <button>Read more</button>
                    </div>
                </Link>
            ))}
        </div>
    </div>
    </div>
  )
}

export default Image