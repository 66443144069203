import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import customFetch from "../../utils/axios";
import axios from "axios";

const initialState = {
    isLoading: false,
    reviews: [],
    title: '',
    rating: '',
    comment: '',
}

export const getAllReviews = createAsyncThunk('allReviews/getReviews', async(_, thunkAPI) => {
    let url = '/review'
    try {
        const response = await axios.get(url)
        console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue('There was an error')
    }
})

export const createReview = createAsyncThunk(
    'review/createReview',
    async (review, thunkAPI) => {
        try {
            const response = await axios.post('/review', review)
            console.log(response.data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.msg)
        }
    }
)
const allReviewsSlice = createSlice({
    name: 'allReviews',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.isLoading = true
        },
        hideLoading: (state) => {
            state.isLoading = false
        },
        handleChange: (state, { payload: { name, value } }) => {
            state[name] = value
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllReviews.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllReviews.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.reviews = payload.reviews
            })
            .addCase(getAllReviews.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload) 
            })
    }
})

export const { showLoading, hideLoading, handleChange } = allReviewsSlice.actions

export default allReviewsSlice.reducer
