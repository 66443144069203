import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import SingleTour from "./Tour/SingleTour";
import { TourData } from "./Tour/TourData";
import { getAllTours } from "../features/allTours/allToursSlice";


const Tour = () => {
  const { tours } = useSelector((store) => store.allTours)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllTours())
  }, [])
  return (
    <div className="mt-8 font-poppins">
      <div className="container mx-auto py-16 flex flex-col items-center">
        <h4 className='text-[#5a8b26] text-[1.05rem] md:text-[1.2rem] tracking-widest'>PACKAGES</h4>
        <h1 className='text-[2rem] md:text-[2.5rem] font-bold text-center'>Perfect Tour Packages </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {tours.map((item, idx) => (
            <SingleTour tour={item} key={idx} description={false} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tour;
