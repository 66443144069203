import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import customFetch from "../../utils/axios";
import authHeader from "../../utils/authHeader";

const initialState = {
    isLoading: false,
    reviews: [],
    review: null
}

export const getTourReviews = createAsyncThunk('tourReviews',
    async(tourId, thunkAPI) => {
        let url = `/tour/${tourId}/reviews`

        try { 
            const response = await customFetch.get(url)
            return response.data 
        } catch (error) {
            return thunkAPI.rejectWithValue('There was an error')
        }
    }
)

export const writeReview = createAsyncThunk('addReview',
    async(review, thunkAPI) => {
        try {
            console.log(thunkAPI.getState().user.user);
            const response = await customFetch.post('/review', review, {
                headers: {
                    Authorization: `Bearer ${thunkAPI.getState().user.user}`
                }
            })
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.msg)
        }
    }
)

const getTourReviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getTourReviews.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTourReviews.fulfilled, (state, { payload }) => {
                state.isLoading = false
                const { reviews } = payload
                state.reviews = reviews
            })
            .addCase(getTourReviews.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload) 
            })
            .addCase(writeReview.pending, (state) => {
                state.isLoading = true
            })
            .addCase(writeReview.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.review = payload
                toast.success('Review successfully added')
            })
            .addCase(writeReview.rejected, (state, { payload }) => {
                state.isLoading = false
                toast.error(payload) 
            })
    }
})

export default getTourReviewsSlice.reducer