import React from 'react'
import { FaRegMoneyBillAlt, FaPlaneDeparture } from "react-icons/fa";
import { TbBrandBooking } from "react-icons/tb";


const AboutItems = () => {
  return (
    <div className='font-poppins'>
      <div className=' flex flex-col gap-10 md:flex-row md:gap-8 justify-between items-center'>
        <div className='flex gap-8'>
          <FaRegMoneyBillAlt color='#5a8b26' size={44} />
          <div className='space-y-4'>
            <p className='font-semibold text-[1.2rem]'>Best Price Guarantee</p>
            <p>There are many variations of but the majority have simply free text.</p>
          </div>
        </div>
        <div className='flex gap-8'>
          <TbBrandBooking color='#5a8b26' size={44} />
          <div className='space-y-4'>
            <p className='font-semibold text-[1.2rem]'>Easy & Quick Booking</p>
            <p>There are many variations of but the majority have simply free text.</p>
          </div>
        </div>
        <div className='flex gap-8'>
          <FaPlaneDeparture color='#5a8b26' size={44} />
          <div className='space-y-4'>
            <p className='font-semibold text-[1.2rem]'>Best Tour Selection</p>
            <p>There are many variations of but the majority have simply free text.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutItems