import React from 'react'
import banner1 from '../assets/images/Africa.jpg'
import { Link } from 'react-router-dom'

const Banner = ({ img, title, urls }) => {
  return (
    <div className='w-full relative h-[50vh] bg-white border-b-[1px] border-gray-200' style={{
        // backgroundImage: `url(${banner1})`,
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // width: '100%',
        // height: '40vh'
    }}>
        <img
              className="d-block w-100 h-[80%] object-cover" 
              src={img}
              alt="First slide"
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "80%",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
            ></div>
        <div className="container flex items-center">
            <h1 className='absolute text-white font-semibold text-[2.4rem] top-[50%] translate-y-[-50%]'>{title}</h1>
            
            <div className='py-3'>
                <p className='font-semibold'><Link to='/' className='hover:text-[#5a8b26] transition-all duration-300 hover:no-underline'
                >Home</Link>  .  <span className='text-[#5a8b26] ml-1'>{title}</span></p>
            </div>
        </div>
    </div>
  )
}

export default Banner