import { FaCar, FaTicketAlt, FaHotel, FaIdCard, FaShip, FaUsers, FaChild, FaHandshake } from "react-icons/fa";
import { IoAirplane } from "react-icons/io5";

export const ServiceData = [
    {
        icon: FaCar,
        name: 'Car Rentals',
        details:'Explore your destination at your own pace with our reliable and well-maintained fleet of vehicles. Whether you need a compact car for urban adventures or a spacious SUV for a family road trip, we have the perfect vehicle to suit your needs.'
    },
    {
        icon: FaTicketAlt,
        name: 'Ticket Booking',
        details:'Seamlessly plan your travel itinerary with our ticket booking services. We handle flight, train, bus and adventure parks reservations, ensuring convenient and hassle-free journeys to your desired destinations. Start plannig your next adventure.'
    },
    {
        icon: FaHotel,
        name: 'Hotel Booking',
        details:'Discover a world of comfort and luxury with our extensive selection of hotels. From budget-friendly accommodations to lavish resorts, we assist you in finding the ideal place to stay that aligns with your preferences and budget.'
    },
    {
        icon: IoAirplane,
        name: 'Airport Transfers',
        details:'Start your journey on the right foot with our professional airport transfer services. We ensure a smooth and comfortable transition from the airport to your hotel, taking care of all your transportation needs.'
    },
    {
        icon: FaIdCard,
        name: 'Visa Assistance',
        details:'Navigating visa requirements can be complex. Our experienced team provides guidance and assistance in obtaining the necessary visas, ensuring a smooth and efficient application process.'
    },
    {
        icon: FaShip,
        name: 'Cruise Booking',
        details:'Embark on an unforgettable voyage with our cruise booking services. Discover breathtaking destinations, indulge in luxurious amenities, and create lasting memories aboard the finest cruise ships.'
    },
    {
        icon: FaUsers,
        name: 'MICE',
        details:'We specialize in organizing successful corporate events, conferences, and exhibitions. Our dedicated team ensures seamless planning, logistics, and execution to create impactful experiences for your business.'
    },
    {
        icon: FaChild,
        name: 'Travel Insurance',
        details:'Your safety and well-being are our top priorities. We offer comprehensive travel insurance coverage to protect you against unforeseen events and provide peace of mind throughout your travels.'
    },
    {
        icon: FaHandshake,
        name: 'Meet & Greet',
        details:'This Service is suitable for individual, families, corporate groups, and VIPs seeking a personalized and seamless arrival experience. With our attention to detail and commitment to exceptional service, we aim to exceed your expectations.'
    },
]