import React from 'react'
import { Navbar, Banner, Footer, ServiceDetails } from '../components'
import img from '../assets/images/honeymoon_bg.jpg'

const ServicePage = () => {
  return (
    <div className='font-poppins'>
      <Navbar />
      <Banner img={img} title='Services' />
      <ServiceDetails />
      <Footer />
    </div>
  )
}

export default ServicePage