import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'

import logo from '../../assets/images/Triplogo.png'
import { Button, Input } from '../../components'
import { registerUser } from '../../features/user/userSlice'

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    confirmPassword: '',
}

const Register = () => {
    const [success, setSuccess] = useState(false)
    const [values, setValues] = useState(initialState)
    const dispatch = useDispatch()
    const { isLoading, user } = useSelector((store) => store.user)

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setValues({ ...values, [name]: value })
    }
    const { firstName, lastName, email, password, confirmPassword, phone } = values

    const onSubmit = (e) => {
        e.preventDefault()
        
        if (password !== confirmPassword) {
           toast.error('Please ensure passwords are equal');
           return
        }
        if (!firstName || !lastName || !email || !password || !confirmPassword || !phone) {
            toast.error('Please Fill Out All Fields')
            return
        }
        dispatch(registerUser({ firstName, lastName, email, password, phone }))
        setSuccess(true)
    }
  return (
    <div className='bg-[#e5e5e5] h-full font-poppins'>
        <div className="container min-h-screen w-full flex flex-col gap-8 items-center py-8 ">
            <Link to='/'>
                <img src={logo} alt="logo" className='h-[6rem] w-[14rem] object-contain hover:cursor-pointer' />
            </Link>
            <div className="w-full md:w-[60%] lg:w-[50%] bg-white rounded-md p-8 lg:p-16 flex flex-col gap-4 items-center">
                <h1 className='text-[2rem] font-semibold'>{success ? 'Verify Email' : 'Register'}</h1>
                {success && (
                    <h2 className='text-center'>An email has been sent to <a href={`mailto:${email}`}>{email}</a> please verify</h2>
                )}
                {!success && (
                    <form className='mt-4 w-full space-y-4' onSubmit={onSubmit}>
                        <Input type='text'  placeholder='First Name' name='firstName' value={values.firstName} handleChange={handleChange} />
                        <Input type='text'  placeholder='Last Name' name='lastName' value={values.lastName} handleChange={handleChange} />
                        <Input type='email'  placeholder='Email address'  name='email' value={values.email} handleChange={handleChange}/>
                        <Input type='password'  placeholder='Password' name='password' value={values.password} handleChange={handleChange}/>
                        <Input type='password'  placeholder='Confirm password' name='confirmPassword' value={values.confirmPassword} handleChange={handleChange} />
                        <Input type='tel'  placeholder='Phone no'  name='phone' value={values.phone} handleChange={handleChange}/>
                        <div className='w-full flex items-center gap-4'>
                            <input type="checkbox" />
                            <p>I agree with the terms and conditions</p>
                        </div>
                        <Button title='submit' disabled={isLoading} />
                    </form>
                )}
                {/* <Link to='' className='links'>
                    Forgot password?
                </Link> */}
                <p>
                    Already have an account? <Link to='/login' className='links'>Login</Link>
                </p>
            </div>
        </div>
    </div>
  )
}

export default Register