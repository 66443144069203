import React from "react";
import { ServiceData } from "./ServiceData";

const SingleService = ({ item }) => {
  return (
    <div className="flex flex-col items-center gap-4 px-4 py-8 bg-white shadow-md hover:shadow-lg transition duration-300">
      <div className="border-2 border-[#5a8b26] p-4 text-[#5a8b26] hover:text-white hover:border-transparent hover:bg-[#5a8b26] transition duration-300">
        <item.icon size={40} />
      </div>
      <h4 className="font-semibold  text-[1.4rem]">{item.name}</h4>
      <p className="text-center text-[16px]">
        {item.details}
      </p>
    </div>
  );
};

export default SingleService;
