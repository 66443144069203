import React from 'react'
import image1 from '../assets/images/people travel.jpg'
import AccordionUsage from './Accordion'

const Destination = () => {
  const items = [
    {
      p1: 'Country',
      p2: 'America'
    },
    {
      p1: 'Visa Requirements',
      p2: 'Personal Documents Requied'
    },
    {
      p1: 'Languages Spoken',
      p2: 'English'
    },
    {
      p1: 'Currency Used',
      p2: 'USD'
    },
    {
      p1: 'Area (km2)',
      p2: '88.000 km2'
    },
  ]

  const recommended = [
    {
      amount: '350',
      title: 'National Yankari Park',
      location: 'Abuja'
    },
    {
      amount: '350',
      title: 'National Yankari Park',
      location: 'Abuja'
    },
    {
      amount: '350',
      title: 'National Yankari Park',
      location: 'Abuja'
    },
    {
      amount: '350',
      title: 'National Yankari Park',
      location: 'Abuja'
    },
  ]
  return (
    <div>
      <div className="container flex flex-col lg:flex-row gap-16 my-16 w-full">
        <div className='w-full space-y-12'>
           <div className='h-[20rem]  md:h-[25rem] '>
            <img src={image1} alt="" className='rounded-lg h-full w-full object-cover' />
           </div>
           <h1 className='text-[2rem] font-semibold'>Discover Spain</h1>
           <div>
            <p>
              Lorem ipsum available isn but the majority have suffered alteratin in some or form injected. Lorem Ipsum. Proin gravida nibh vel velit auctor aliqueenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. vulputate cursus a sit amet mauris. Morbi accumsan ipsum veliam nec tellus a odio tincidunt auctor.
            </p>
            <br />
            <p>
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some injected or words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary,
            </p>
           </div>
           <div>
            <h2 className='text-[1.6rem] font-semibold'>Overview</h2>
            <div className='mt-4'>
              {items.map((item, idx) => (
                <>
                  <span key={idx} className='flex flex-col md:flex-row font-semibold capitalize py-4 items-start md:items-center justify-between'>
                    <p>{item.p1}</p>
                    <p className='text-[#5a8b26]'>{item.p2}</p>  
                  </span>
                  <hr />
                </>
              ))}
            </div>
           </div>
           <AccordionUsage />
        </div>
        {/* <div className='w-full lg:w-2/5'>
           <div className='border-[1px] rounded-lg p-4 space-y-4'>
                <h2 className='font-semibold text-[1.2rem]'>Recommended Packages</h2>
                <div>
                  {recommended.map((i, idx) => (
                    <div className=''>
                      <div className='flex py-4 gap-4'>
                        <img src={image1} className='h-20 w-20 object-cover rounded-lg' alt="" />
                        <div>
                          <p className='text-[#5a8b26] font-semibold'>${i.amount}</p>
                          <p className='font-semibold'>{i.title}</p>
                          <p>{i.location}</p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
           </div>
        </div> */}
      </div>
    </div>
  )
}

export default Destination