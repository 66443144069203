import React, { useState } from 'react'
import { Link, useLocation, Navigate } from 'react-router-dom'


import logo from '../../assets/images/Triplogo.png'
import { Button, Input } from '../../components'
import { toast } from 'react-toastify'
import customFetch from '../../utils/axios'


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const initialState = {
    password: '',
    confirmPassword: ''
}

const ResetPassword = () => {
    const [values, setValues] = useState(initialState)
    const [success, setSuccess] = useState(false)
    const query = useQuery()

    
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setValues({ ...values, [name]: value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const { password, confirmPassword } = values
        if (!password && !confirmPassword) {
            toast.error('Please fill all fields')
        }

        if (password !== confirmPassword) {
            toast.error('Passwords must match')
        }

        try {
            const { data } = await customFetch.post('/auth/reset-password', {
                password,
                token: query.get('token'),
                email: query.get('email')
            })
            setSuccess(true)
        } catch (error) {
            toast.error(error.response.data.msg)
        }
    }
    if (success) {
        return <Navigate to='/login' />
    }
  return (
    <div className='bg-[#e5e5e5] h-full font-poppins'>
        <div className="container h-full w-full flex flex-col gap-8 items-center py-8 ">
            <Link to='/'>
                <img src={logo} alt="logo" className='h-[6rem] w-[14rem] object-contain hover:cursor-pointer' />
            </Link>
            <div className="w-full md:w-[60%] lg:w-[50%] bg-white rounded-md p-8 lg:p-16 flex flex-col gap-4 items-center">
                <h1 className='text-[2rem] font-semibold'>New Password Reset</h1>
                <p className='text-center'>Enter your new password</p>
                <form className='mt-4 w-full space-y-4' onSubmit={handleSubmit}>
                    <Input name='password' handleChange={handleChange} value={values.password} type='password'  placeholder='Password' />
                    <Input name='confirmPassword' handleChange={handleChange} value={values.confirmPassword} type='password'  placeholder='Confirm Password' />
                    <Button title='submit' />
                </form>
                <p>
                    Don't have an account? <Link to='/register' className='links'>Register</Link>
                </p>
            </div>
        </div>
    </div>
  )
}

export default ResetPassword