import React, { useEffect } from 'react'
import { Navbar, About, Hero, Newsletter, Services, Tours, Tour, Footer } from '../components'
import Slides from '../components/Slides'
import Testimonials from '../components/Testimonial/Testimonials'
import Image from '../components/Gallery/Image'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='font-poppins overflow-x-hidden bg-[#fbfbfb]'>
      <Navbar />
      <Hero />
      <About />    
      <Tour />
      <Services />
      <Image />
      {/* <Tours /> */}
      <Newsletter />
      <Testimonials />
      {/* <Slides /> */}
      <Footer />
    </div>
  )
}

export default Home