import React from 'react'
import honeymoon from '../assets/images/honeymoon_bg.jpg'
import { TiTick } from "react-icons/ti";

const Newsletter = () => {
  return (
    <div className='bg-fixed bg-blend-darken font-poppins' style={{ backgroundImage: `url(${honeymoon}), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.8))`, width: '100%', height: '100%', objectFit: 'contain', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundBlendMode: 'darken'}}>
      <div className="container w-full mx-auto flex flex-col md:flex-row items-center gap-8 justify-center py-16">
        <div className='space-y-8 w-full md:w-1/2'>
          <div className='text-start'>
            <p className='uppercase text-[#5a8b26] tracking-wider'>mega offer</p>
            <h1 className='font-bold text-white text-[2rem]'><span className='text-[#5a8b26]'>30% OFF</span> For Honeymoon</h1>
          </div>
          <p className='text-semibold text-white'>
            Don't miss out on this incredible opportunity to discover Paradise. Book your tour package today and embark on an unforgettable adventure filled with awe-inspiring sights, cultural immersion, and exhilarating experiences. Create lasting memories that will stay with you forever.
          </p>
          <div className='text-white'>
            <p className='flex items-center gap-3'><span><TiTick size={24} color='#5a8b26' /></span> Sepectacular scenic views</p>
            <p className='flex items-center gap-3'><span><TiTick size={24} color='#5a8b26' /></span> Luxurious Accommodation</p>
            <p className='flex items-center gap-3'><span><TiTick size={24} color='#5a8b26' /></span>Unforgettable Adventures</p>
          </div>
        </div>
        <div className='flex flex-col w-full md:w-1/2 justify-center items-center'>
         <div className='bg-white w-full'>
          <h1 className='w-full text-center text-[2rem] p-4 font-extrabold text-white bg-[#5a8b26]'>Get Details</h1>
            <form className='flex flex-col w-full p-16 space-y-4'>
              <input className='border-2 px-3 py-2 border-gray-300 outline-none' type="text" placeholder='Your name' />
              <input className='border-2 px-3 py-2 border-gray-300 outline-none' type="text" placeholder='Your email' />
              <select className='border-2 px-3 py-2 border-gray-300 outline-none' name="" id="" >
                <option value="">Asia</option>
                <option value="">Africa</option>
                <option value="">Europe</option>
                <option value="">America</option>
              </select>
              <button className='text-white bg-[#5a8b26] p-4'>Submit</button>
            </form>
         </div>
        </div>
      </div>
    </div>
  )
}

export default Newsletter