import React, { useContext, useEffect, useReducer } from "react";
import reducer from '../reducers/tours_reducer'
import {
    GET_SINGLE_TOUR_BEGIN,
    GET_SINGLE_TOUR_SUCCESS,
    GET_SINGLE_TOUR_FAILURE,
    GET_TOURS_BEGIN,
    GET_TOURS_SUCCESS,
    GET_TOURS_FAILURE,
} from '../actions'
import { url } from '../utils/constants'
import axios from "axios";


const initialState = {
    tours_loading: false,
    tours_error: false,
    tours: [],
    featured_tours: [],
}

const ToursContext = React.createContext()

export const ToursProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const fetchTours = async(url) => {
        dispatch({ type: GET_TOURS_BEGIN })
        try {
            const response = await axios.get(`${url}/tour`)
            const tours = response.data
            dispatch({ type: GET_TOURS_SUCCESS, payload: tours })
        } catch (error) {
            dispatch({ type: GET_TOURS_FAILURE })
        }
        
    }

    useEffect(() => {
        fetchTours(url)
    }, [])
    return (
        <ToursContext.Provider value={{ ...state, fetchTours }}>
            {children}
        </ToursContext.Provider>
    )
}

export const useToursContext = () => {
    return useContext(ToursContext)
}