import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'

import logo from '../../assets/images/Triplogo.png'
import { Button, Input } from '../../components'
import { loginUser } from '../../features/user/userSlice'
import { Loader } from '../../components'

const initialState = {
    email: '',
    password: ''
}

const Login = () => {
    const [values, setValues] = useState(initialState)
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoading, user } = useSelector((store) => store.user)

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setValues({ ...values, [name]: value })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const { email, password } = values
        if (!email || !password) {
            toast.error('Please Fill Out All Fields')
            return
        }
        dispatch(loginUser({ email, password }))   
    }
    useEffect(() => {
        if (user) {
            setTimeout(() => {
                navigate('/')
            }, 2000)
        }
    }, [user])

    if (isLoading) return <Loader />

  return (
    <div className='bg-[#e5e5e5] h-screen font-poppins'>
        <div className="container h-full w-full flex flex-col gap-8 items-center py-8 ">
            <Link to='/'>
                <img src={logo} alt="logo" className='h-[6rem] w-[14rem] object-contain hover:cursor-pointer' />
            </Link>
            <div className="w-full md:w-[60%] lg:w-[50%] bg-white rounded-md p-8 lg:p-16 flex flex-col gap-4 items-center">
                <h1 className='text-[2rem] font-semibold'>Login</h1>
                <form className='mt-4 w-full space-y-4' onSubmit={onSubmit}>
                    <Input type='email'  placeholder='Email address' name='email' value={values.email} handleChange={handleChange} />
                    <Input type='password'  placeholder='Password' name='password' value={values.password} handleChange={handleChange}  />
                    <Button title='submit' />
                </form>
                <Link to='/forgot-password' className='links'>
                    Forgot password?
                </Link>
                <p>
                    Don't have an account? <Link to='/register' className='links'>Register</Link>
                </p>
            </div>
        </div>
    </div>
  )
}

export default Login