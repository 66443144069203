import React from 'react'
import SingleService from './SingleService'
import { ServiceData } from './ServiceData'

const ServiceDetails = () => {
  return (
    <div className='bg-[#fbfbfb]'> 
      <div className='container mx-auto text-center py-16 space-y-4'>
        <h4 className='text-[#5a8b26] text-[1.05rem] md:text-[1.2rem] tracking-widest uppercase'>our services</h4>
        <h1 className='text-[2rem] md:text-[2.5rem] font-bold'>Tours & Travels Services</h1>
        <div className='mt-4 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            {ServiceData.map((item, idx) => (
                <SingleService item={item} key={idx} />
            ))}
        </div>
    </div>
    </div>
  )
}

export default ServiceDetails