import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from './Input';
import { Button, Loader } from '../components'
import { postTour } from '../features/tourSlice/tourSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import ImageUploader from './UploadImages';

const AddForm = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((store) => store.tour)
  const cloud_name = 'jay247'
  const preset_key = 'nu1hqqfk'

  const [images, setImages] = useState([]);
  const [uploadedUrls, setUploadedUrls] = useState([]);
  const [tourData, setTourData] = useState({
    name: '',
    location: '',
    rating: 0,
    amount: 0,
    images: [],
    duration: '',
    noOfPersons: 0,
    category: '',
    overview: '',
    minAge: 0,
    excluded: [],
    included: [],
    supplements: [],
    cancellation: [],
    notes: [],
    table: [[]],
    tourPlans: [[]],
    featured_tour: false
  });

  const handleArrayChange = (e) => {
    const { name, value } = e.target;
    setTourData({
      ...tourData,
      [name]: value.split('/').map(item => item)
    });
  };

  const handleTourPlansChange = (e) => {
    const { value } = e.target;
    // Split the value by line breaks to get individual arrays
    const plans = value.split('\n').map(plan => plan.split('/').map(item => item));
    setTourData({
      ...tourData,
      tourPlans: plans,
    });
  };

  const handleTourTableChange = (e) => {
    const { value } = e.target;
    // Split the value by line breaks to get individual arrays
    const plans = value.split('\n').map(plan => plan.split('/').map(item => item));
    setTourData({
      ...tourData,
      table: plans,
    });
  };


  const handleFeaturedTourChange = (e) => {
    const { checked } = e.target;
    setTourData({
      ...tourData,
      featured_tour: checked
    });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setTourData({
      ...tourData,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages(selectedImages);
  };


  const uploadImages = async () => {
    const uploadedUrls = [];
    for (const image of images) {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', preset_key);

      try {
        const response = await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          uploadedUrls.push(data.secure_url);
        } else {
          console.error('Failed to upload image:', image.name);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

    setUploadedUrls(uploadedUrls);
    setTourData({
      ...tourData,
      images: uploadedUrls
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await uploadImages()
    // upload tourData with the uploadedUrls
    // setTourData(prevTourData => ({
    //   ...prevTourData,
    //   images: uploadedUrls
    // })) 

    dispatch(postTour(tourData))
    console.log(tourData);
  };
  
 if (isLoading) return <Loader />
  return (
    <div className="max-w-2xl mx-auto p-4 font-poppins">
      {/* <ImageUploader /> */}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block font-medium">Name:</label>
          <Input name='name' type='text' id='name' value={tourData.name} handleChange={handleChange}/>
        </div>
        <div>
          <label htmlFor="category" className="block font-medium">Category:</label>
          <Input name='category' type='text' id='category' value={tourData.category} handleChange={handleChange}/>
        </div>
        <div>
          <label htmlFor="overview" className="block font-medium">Overview:</label>
          <textarea id="overview" name="overview" value={tourData.overview} onChange={handleChange} className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none" />
        </div>
        <div>
          <label htmlFor="amount" className="block font-medium">Amount:</label>
          <Input name='amount' type='number' id='amount' value={tourData.amount} handleChange={handleChange}/>
        </div>
        <div>
          <label htmlFor="minAge" className="block font-medium">Minimum Age:</label>
          <Input name='minAge' type='number' id='minAge' value={tourData.minAge} handleChange={handleChange}/>
        </div>
        <div>
          <label htmlFor="noOfPersons" className="block font-medium">No of Persons:</label>
          <Input name='noOfPersons' type='number' id='noOfPersons' value={tourData.noOfPersons} handleChange={handleChange}/>
        </div>
        <div>
          <label htmlFor="duration" className="block font-medium">Duration:</label>
          <Input name='duration' type='number' id='duration' placeholder='e.g 3Days' value={tourData.duration} handleChange={handleChange}/>
        </div>
        <div>
          <label htmlFor="location" className="block font-medium">Location:</label>
          <Input type="text" id="location" name="location" value={tourData.location} handleChange={handleChange} />
        </div>
        <div>
          <label htmlFor="rating" className="block font-medium">Rating:</label>
          <Input type="number" id="rating" name="rating" value={tourData.rating} handleChange={handleChange} />
        </div>
        <div>
          <label htmlFor="excluded" className="block font-medium">Excluded:</label>
          <textarea id="excluded" name="excluded" value={tourData.excluded.join('/')} onChange={handleArrayChange} className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none" />
        </div>
        <div>
          <label htmlFor="included" className="block font-medium">Included:</label>
          <textarea id="included" name="included" value={tourData.included.join('/')}onChange={handleArrayChange} className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none" />
        </div>
        <div>
          <label htmlFor="supplements" className="block font-medium">Supplements:</label>
          <textarea id="supplements" name="supplements" value={tourData.supplements.join('/')}onChange={handleArrayChange} className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none" />
        </div>
        <div>
          <label htmlFor="notes" className="block font-medium">Notes:</label>
          <textarea id="notes" name="notes" value={tourData.notes.join('/')}onChange={handleArrayChange} className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none" />
        </div>
        <div>
          <label htmlFor="cancellation" className="block font-medium">Cancellation:</label>
          <textarea id="cancellation" name="cancellation" value={tourData.cancellation.join('/')}onChange={handleArrayChange} className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none" />
        </div>
        <div>
          <label htmlFor="table" className="block font-medium">Detail Table:</label>
          <textarea id="table" name="table" value={tourData.table.map(plan => plan.join('/')).join('\n')} onChange={handleTourTableChange} className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none" />
        </div>
        <div>
          <label htmlFor="tourPlans" className="block font-medium">Tour Plans:</label>
          <textarea id="tourPlans" name="tourPlans" value={tourData.tourPlans.map(plan => plan.join('/')).join('\n')} onChange={handleTourPlansChange} className="border-2 bg-[#fff] px-4 py-2 w-full rounded-md outline-none" />
        </div>
        <div>
          <label htmlFor="featuredTour" className="flex items-center">
            <input type="checkbox" id="featuredTour" name="featured_tour" checked={tourData.featured_tour} onChange={handleFeaturedTourChange} className="mr-2" />
            <span className="font-medium">Featured Tour</span>
          </label>
        </div>
        <div>
          <label htmlFor="images" className="block font-medium">Images:</label>
          {/* <input type="file" id='images' name="images" multiple onChange={handleImageChange} /> */}
          <input type="file" id="images" name="images" accept="image/*" formEncType='multipart/form-data' multiple onChange={handleImageChange} className="input-field" />
          {/* {tourData.images && tourData.images.map((image, index) => (
            <div key={index} className='flex gap-2 w-full'>
              <img src={image} alt={`Image ${index}`} className="mt-2 w-32 h-32 object-cover" />
            </div>
          ))} */}
        </div>
        <div>
          <button type="submit" className="bg-[#5a8b26] px-4 py-2 text-white rounded-md">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddForm;
